import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styles from "./portfolio-grid.module.scss"

export default class PortfolioGrid extends React.Component {
  buildGrid(portfolioSingle) {
    const divs = []
    portfolioSingle.forEach((portfolioSingle, index) => {
      const { portfolioSingleImage } = { ...portfolioSingle.frontmatter }
      const { slug } = { ...portfolioSingle.fields }
      const newSlug = slug.replace(/\/$/, "")
      divs.push(
        <div key={index} className={styles.item}>
          <Link to={newSlug}>
            <Img
              key={index}
              fluid={portfolioSingleImage.childImageSharp.fluid}
            />
          </Link>
        </div>
      )
    })
    return divs
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            portfolioSingle: allMarkdownRemark(
              filter: {
                frontmatter: { templateKey: { eq: "portfolioSingle" } }
              }
            ) {
              nodes {
                frontmatter {
                  portfolioSingleImage {
                    childImageSharp {
                      fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                fields {
                  slug
                }
              }
            }
          }
        `}
        render={query => {
          return (
            <div className={styles.portfolioGrid + " padding-s"}>
              {this.buildGrid(query.portfolioSingle.nodes)}
            </div>
          )
        }}
      />
    )
  }
}
// TODO Max width of thumb - check it's ok if container gets bigger
